import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetPages {
    // ! Список страниц
    static async GetPagesList(page, data) {
        const response = await axios.get(`${egoda_url}/page/list/${page}/`, {
            params: data,
            withCredentials: true
        });
    
        return response;
    }
    // Получить страницу
    static async GetPageDetail(slug) {
        const response = await axios.post(`${egoda_url}/page/detail/`, {
            slug: slug
        },
        {
            withCredentials: true
        });

        return response;
    }
    // ! Изменить страницу
    static async UpdatePage(data) {
        const response = await axios.put(`${egoda_url}/page/edit/`, data, {
            withCredentials: true
        })

        return response;
    }
}