import React, { useEffect, useState } from "react"
import slugify from 'slugify';
import { useLocation, useNavigate, useParams } from "react-router";
import img_pack from "../img/img";
import CustomSelect from "./components/Custom_select";
import { AdminStoreRedRootBase, AdminStoreRoot, egoda_url } from "../utils/consts";
import EditorComponent from "./components/EditorCompoenent";
import { useFetching } from "../hooks/UseFetching";
import DatePicker from "./components/DatePicker";
import ProductRedTabs from "./components/ProductRedTabs";
import GetStore from "../API/GetStore";
import StoreRedCats from "./components/AdminRedmenu/StoreRed_cats";
import PlayerComponent from "./components/PlayerComponents";
import { Link } from "react-router-dom";

const AdminStoreRedPage = () => {

    // const [mediafilesPodata, setMediafilesPodata] = useState({isMulti: true});
    const [fileId, setFileId] = useState('');

    const [tagsBtns, setTagsBtns] = useState([
        {
            id: 0,
            name: 'название',
            tag: '{{название}}',
        },
        {
            id: 1,
            name: 'родительская категория',
            tag: '{{родительская_категория}}',
        },
        {
            id: 2,
            name: 'категория',
            tag: '{{категория}}',
        },
        {
            id: 3,
            name: 'атрибут: Ширина: название',
            tag: '{{атрибут: Ширина: название}}',
        },
        {
            id: 4,
            name: 'атрибут: Ширина: значение',
            tag: '{{атрибут: Ширина: значение}}',
        },
        {
            id: 5,
            name: 'количество',
            tag: '{{количество}}',
        },
        {
            id: 6,
            name: 'цена',
            tag: '{{цена}}',
        },
    ])

    const [productRedTabsData, setProductRedTabsData] = useState({
        promoPriceToggle: false,
        price: '',
        promoPrice: '',
        article: '',
        quantityProducts: '',
        invManagToggle: false,
        dontShowToggle: false,
        is_popular: false,
        productAtr: [],
        productTabs: [],
        product_supplier_info: [],
    })

    const [metaTitle, setMetaTitle] = useState('')
    const [metaDesc, setMetaDesc] = useState('')
    const [activeInput, setActiveInput] = useState(null);

    const [slug, setSlug] = useState('')
    const [statusSelected, setStatusSelected] = useState([])
    const [catsSelected, setCatsSelected] = useState([])
    const [brandSelected, setBrandSelected] = useState([])
    const [allBrrands, setAllBrrands] = useState([]);

    const [selectedImage, setSelectedImage] = useState('');
    const [galleryImgs, setGalleryImgs] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState('');

    const [publishDate, setPublishDate] = useState(new Date());

    const [editorPreviewShortDesc, setEditorPreviewShortDesc] = useState('')
    const [editorContentShortDesc, setEditorContentShortDesc] = useState({})
    const [editorPreviewFullDesc, setEditorPreviewFullDesc] = useState('')
    const [editorContentFullDesc, setEditorContentFullDesc] = useState({})

    const {slugParam} = useParams()

    const location = useLocation()
    const navigate = useNavigate()

    const isEdit = location.pathname.includes(AdminStoreRedRootBase)

    // Базовые запросы \ первичный рендер
    // Получение всех брендов
    const [GetStoreBrandsAll] = useFetching( async () => {
        const res = await GetStore.GetStoreBrandsAll()
        // console.log('GetStoreBrandsAll complete:' , res.data)
        setAllBrrands(res.data.data)
    })

    useEffect(() => {
        GetStoreBrandsAll()
    },[])

    // Получение поста при изменении поста

    const [GetAdminProduct] = useFetching( async (slugParam) => {
        const res = await GetStore.GetAdminProduct(slugParam)
        // console.log('GetAdminProduct complete:' , res.data.data)
        const form = document.querySelector('.new_articles_form')
        const product = res.data.data
        form.elements.title.value = product?.name
        setMetaTitle(res.data.data?.meta_title)
        setMetaDesc(res.data.data?.meta_desc)
        setSlug(product?.slug)
        setCatsSelected([{value: product?.category_slug, name: product?.category_name}])
        setStatusSelected(product?.status === 'PB' ? [{value: 'PB', name: 'Опубликованно'}] : [{value: 'DF', name: 'Черновик'}])
        setPublishDate(new Date(product?.created))
        product?.short_desc && setEditorPreviewShortDesc(product?.short_desc)
        product?.desc && setEditorPreviewFullDesc(product?.desc)
        setFileId(product.image || '')
        setSelectedImage(product.image_url || '')
        setGalleryImgs(product.gallery_images.map((el) => ({id: el.image, file: el.image_url})))
        setSelectedVideo( {id: product.video, file: product.video_url})
        setBrandSelected(product.brand ? [{value: product?.brand_info.slug, name: product?.brand_info.name}] : [])
        setProductRedTabsData(prevState => ({
            ...prevState,
            price: product.price || prevState.price,
            promoPrice: product.promo_price || prevState.promoPrice,
            promoPriceToggle: product.promoPriceToggle || prevState.promoPriceToggle,
            quantityProducts: product.quantity,
            productAtr: product.product_atr,
            productTabs: product.product_tab,
            invManagToggle: product.is_inventory,
            dontShowToggle: product.is_unvisible_out_stock,
            is_popular: product.is_popular,
            article: product.item_number || prevState.article,
            product_supplier_info: product.product_supplier_info || prevState.product_supplier_info,
          }));
    })

    useEffect(() => {
        if(slugParam && isEdit) {
            GetAdminProduct(slugParam)
        }
    },[slugParam])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    // // // Отправление формы \ создание поста \ обновление поста

    const [AddProduct] = useFetching( async (data) => {
        const res = await GetStore.AddProduct(data)
        // console.log('AddProduct complete:' , res.data)
        // navigate(`${AdminStoreRoot}`)
        return res;
    })

    const [UpdateProduct] = useFetching( async (data, slug) => {
        const res = await GetStore.UpdateProduct(data, slug)
        // console.log('UpdateProduct complete:' , res.data)
        // navigate(`${AdminStoreRoot}`)
        return res;
    })
    
    function submit(e) {
        e.preventDefault()
        
        if (catsSelected.length > 0 && statusSelected.length > 0) {

            const productData = {
                "is_popular": productRedTabsData?.is_popular || false,
                "name": e.target.elements.title.value,
                "slug": (slug !== e.target.elements.slug.value) ? slugify(e.target.elements.slug.value, { lower: true }) : slug,
                "item_number": productRedTabsData?.article || '',
                "category": catsSelected[0]?.value,
                "image": fileId || '',
                "video": selectedVideo.id || null, 
                "brand": brandSelected[0]?.value || '',
                "short_desc": editorContentShortDesc,
                "desc": editorContentFullDesc,
                "price": productRedTabsData?.price || 0,
                "promo_price": productRedTabsData?.promoPrice || null,
                "status": statusSelected[0].value || '',
                "is_inventory": productRedTabsData?.invManagToggle || false,
                "is_unvisible_out_stock": productRedTabsData?.dontShowToggle || false,
                "quantity": productRedTabsData?.quantityProducts || null,
                "meta_title": metaTitle || null,
                "meta_desc": metaDesc || null,
                "gallery_images": galleryImgs?.map((el) => (
                    {
                        "image": el.id,
                        "order": null
                    }
                )),
                "product_atr": productRedTabsData?.productAtr?.map(item => ({
                    ...item,
                    id: null,
                    order: null,
                })),
                "product_tab": productRedTabsData?.productTabs?.map(item => ({
                    ...item,
                    id: null,
                })),
            }

            if (isEdit) {
                // console.log('Обновление', slugParam)
                UpdateProduct(productData, slugParam)
            } else {
                // console.log('Добавление')
                AddProduct(productData)
            }

        } else {
            alert('Не выбрана категория')
        }
        
    }
    
    function slugifyTitle(e) {
        const newTitle = e.target.value;
        const newSlug = slugify(newTitle, { lower: true });
        setSlug(newSlug)
    }

    // // Получение файла с попапа медиафайлов

    useEffect(() => {
        if (location.state?.confirmFile) {
            // Логика на confirmFile
            if(location.state.confirmFile.holder) {
                if (location.state.confirmFile.holder === 'video') {
                    setSelectedVideo(location.state.confirmFile)
                } 
            } else {
                if(Array.isArray(location.state.confirmFile)) {
                    setGalleryImgs(location.state.confirmFile)
                } else {
                    setFileId(location.state.confirmFile.id)
                    setSelectedImage(location.state.confirmFile.file)
                }
            }
            // Обнуление состояния confirm в истории
            location.state.confirmFile = undefined
            window.history.replaceState({}, '')
        }
    }, [location.state])

    // Добавление тега в активный инпут
    const handleAddTag = (tag) => {
        if (activeInput === 'metaTitle') {
            setMetaTitle((prev) => (prev ? prev + ' ' + tag : tag));
        } else if (activeInput === 'metaDesc') {
            setMetaDesc((prev) => (prev ? prev + ' ' + tag : tag));
        }
    };

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
                <button onClick={() => navigate(-1)} className="row-10-a">
                    <img style={{minWidth: '15px', maxWidth: '15px', maxHeight: '14px', minHeight: '14px'}} src={img_pack.back_icon} alt="back_icon" />
                    <div className="h-14-400-i">Вернуться назад</div>
                </button>
                <div className="col-5">
                    <input onChange={slugifyTitle} name="title" className="base-input h-16-400-i gr7" placeholder="Название товара" type="text" required/>
                    <input style={{padding: '5px 14px'}} name="slug" className="base-input h-12-400-i gr7" placeholder="slug" type="text" defaultValue={slug} required/>
                </div>
                <div className="h-16-500-i" style={{padding: '10px'}}>Краткое описание товара</div>
                <div className="admin_content_base">
                    <div className="editorJs_container editor_min">
                        <EditorComponent editorPreview={editorPreviewShortDesc} setEditorContent={setEditorContentShortDesc} holder={'editorjs_1'}/>
                    </div>
                </div>  
                <div className="h-16-500-i" style={{padding: '10px'}}>Данные товара</div>
                <ProductRedTabs productRedTabsData={productRedTabsData} setProductRedTabsData={setProductRedTabsData}></ProductRedTabs>
                <div className="h-16-500-i" style={{padding: '10px'}}>Полное описание товара</div>
                <div className="admin_content_base">
                    <div className="editorJs_container editor_min">
                        <EditorComponent editorPreview={editorPreviewFullDesc} setEditorContent={setEditorContentFullDesc} holder={'editorjs_2'}/>
                    </div>
                </div>
                <div className="col-10" style={{padding: '10px'}}>
                    <div className="h-16-500-i">SEO</div>
                    <div className="h-12-500-i">SEO настройки можно задавать в общем виде. Можно задавать на уровне выше: в категориях и родительских категориях. В случае, если в товаре не заданы настройки, то будут использованы настройки из категории, если не заданы там, то из родительской категории.</div>
                </div>
                <div className="base_table base_content_1">
                    <table>
                        <tbody>
                            <tr>
                                <td className="h-16-400-i">Meta заголовок</td>
                                <td><input 
                                    onFocus={() => setActiveInput('metaTitle')}
                                    name="meta_title" 
                                    value={metaTitle || ''} 
                                    onChange={(e) => setMetaTitle(e.target.value)} 
                                    type="text" 
                                    className="base-input h-14-400-i" 
                                    placeholder="{{родительская категория}} {{название}} купить " />
                                </td>
                            </tr>
                            <tr>
                                <td className="h-16-400-i">Meta описание</td>
                                <td><input 
                                    onFocus={() => setActiveInput('metaDesc')} 
                                    name="meta_desc" 
                                    value={metaDesc || ''} 
                                    onChange={(e) => setMetaDesc(e.target.value)} 
                                    type="text" 
                                    className="base-input h-14-400-i" 
                                    placeholder="{{родительская категория}} {{название}} купить " />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='tags-row' style={{padding: '20px'}}>
                        {tagsBtns?.map((el) => (
                            <button key={el.id} className='ba h-12-500-i gr7 tag-button' type="button" onClick={() => handleAddTag(`${el.tag}`)} >{el.name}</button>
                        ))
                        }
                    </div>
                </div>
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
                <div className="new_articles_r_sidebar_block">
                    <div className="col-20">
                        <div className="col-5">
                            <span className="h-14-500-i c-3">Дата публикации</span>
                            <DatePicker date={publishDate} setDate={setPublishDate} className={'h-12-400-i gr7'}/>
                            {/* <input name="date" placeholder="yyyy.MM.dd" className="base-input h-12-400-i gr7" type="text" required/> */}
                        </div>
                        <div className="col-5">
                            <StoreRedCats catsSelected={catsSelected} setCatsSelected={setCatsSelected} categorySingleSelect={true}/>
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i c-3">Статус</span>
                            <CustomSelect allOption={[{id:1, name: 'Опубликованно', slug: 'PB'},{id:2, name: 'Черновик', slug: 'DF'}]} ActiveItems={statusSelected} setActiveItems={setStatusSelected} single={true} enclosure={false} placeholder={'Выбрать статус'}/>
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i c-3">Бренд</span>
                            <CustomSelect allOption={allBrrands} ActiveItems={brandSelected} setActiveItems={setBrandSelected} single={true} enclosure={false} placeholder={'Выбрать бренд'}/>
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i c-3">Изображение</span>
                            {selectedImage ?
                            <div className="preview_img">
                                <img src={(selectedImage instanceof Blob || selectedImage instanceof File) ? URL.createObjectURL(selectedImage) : `${egoda_url}${selectedImage}`} alt="img"/>
                            </div>
                            :
                            <div className="mediafilesPo_input">
                                <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {isMulti: false} } })}></button>
                            </div>
                            }
                            <button onClick={() => {setSelectedImage(''); setFileId('')}} type="button" className="h-12-400-i txtc">Удалить изображение</button>
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i c-3">Галерея товара</span>
                            {galleryImgs.length > 0 ?
                            <div className="gallery_preview_img">
                                {galleryImgs.map((el) => (
                                    <img key={el.id} src={`${egoda_url}${el.file}`} alt="img" />
                                ))}
                            </div>
                            :
                            <div className="mediafilesPo_input">
                                <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {isMulti: true} } })}></button>
                            </div>
                            }
                            <button onClick={() => {setGalleryImgs([])}} type="button" className="h-12-400-i txtc">Удалить изображения</button>
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i c-3">Видео товара</span>
                            {selectedVideo.file ?
                            <div className="gallery_preview_img">
                                <PlayerComponent videoUrl={`${egoda_url}${selectedVideo.file}`}></PlayerComponent>
                            </div>
                            :
                            <div className="mediafilesPo_input">
                                <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {video: true, holder: 'video'} } })}></button>
                            </div>
                            }
                            <button onClick={() => {setSelectedVideo('')}} type="button" className="h-12-400-i txtc">Удалить видео</button>
                        </div>
                    </div>
                </div>

            </div>
        </form>
        
    )
}

export default AdminStoreRedPage;