import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

const LazyImage = ({ src, alt, placeholder, className, style, title, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { ref, inView } = useInView({
    triggerOnce: true,   // Загружается только один раз при попадании в видимость
    threshold: 0.1,      // 10% элемента должны быть видны
  });

  const handleImageLoad = () => {
    setIsLoading(false); // Скрыть лоадер после загрузки изображения
  };

  return (
    <>
      {/* {isLoading && (
        <div className="loader"></div>
      )} */}
      <img
        title={title}
        ref={ref}
        src={inView ? src : placeholder} // Показываем заглушку, пока не видно изображение
        alt={alt}
        onLoad={handleImageLoad}
        style={{
          opacity: inView && !isLoading ? 1 : 0, // Плавное появление
          transition: 'opacity 0.3s ease-in-out',
          ...style, // Сохраняем все переданные стили
        }}
        className={`lazy-load ${className || ''}`} // Сохраняем переданный класс
        {...props} // Прокидываем остальные props (например, `onClick`, `onError`, и т.д.)
      />
    </>
  );
};

export default LazyImage;
