import React, { useEffect, useState } from "react"
import img_pack from "../img/img";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";
import StoreSettingsTabs from "./components/StoreSettingsTabs";

const AdminStoreSettings = () => {

    const [settingsTabs, setSettingsTabs] = useState({
        meta_title_template: '',
        meta_desc_template: '',
    })

    // Базовые запросы \ первичный рендер

    const [GetStoreSettings] = useFetching( async () => {
        const res = await GetStore.GetStoreSettings()
        // console.log('GetStoreSettings complete:' , res.data)
        setSettingsTabs(prevState => ({
            ...prevState,
            meta_title_template: res.data.detail?.meta_title_template,
            meta_desc_template: res.data.detail?.meta_desc_template,
        }));
    })

    useEffect(() => {
        GetStoreSettings()
    },[])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    const [SaveStoreSettings] = useFetching( async (data) => {
        const res = await GetStore.SaveStoreSettings(data)
        // console.log('SaveStoreSettings complete:' , res.data)
        return res;
    })

    function submit(e) {
        e.preventDefault()
        const data = {
            'meta_title_template': settingsTabs?.meta_title_template || '',
            'meta_desc_template': settingsTabs?.meta_desc_template || ''
        }
        SaveStoreSettings(data)
    }

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
                <div className="h-16-500-i" style={{padding: '10px'}}>Настройки</div>
                <StoreSettingsTabs settingsTabs={settingsTabs} setSettingsTabs={setSettingsTabs}/>
                {/* <FeedbackSettingsTabs settingsTabs={settingsTabs} setSettingsTabs={setSettingsTabs}/> */}
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
            </div>
        </form>
        
    )
}

export default AdminStoreSettings;