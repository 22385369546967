import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetStore {
// Товары
    // Получить cписок товаров (Пользователь) пагинация 
    static async UsersProductsAll(productData, page) {
        const response = await axios.post(`${egoda_url}/shop/product/list/${page}/`, productData, {
            withCredentials: true
        });

        return response;
    }
    // Получить cписок товаров (Админка) Без пагинации   
    static async ProductsAllWithoutPagination(productData) {
        const response = await axios.post(`${egoda_url}/shop/product/listallwithoutpagination/`, productData, {
            withCredentials: true
        });

        return response;
    }
    // Получить cписок товаров (Админка)    
    static async ProductsAll(page, productData) {
        const response = await axios.post(`${egoda_url}/shop/product/listall/${page}/`, productData, {
            withCredentials: true
        });

        return response;
    }
    // Удалить товар
    static async DeleteProduct(slug) {
        const response = await axios.delete(`${egoda_url}/shop/product/detail/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Добавить товар
    static async AddProduct(productData) {
        const response = await axios.post(`${egoda_url}/shop/product/add/`, productData, {
            withCredentials: true
        });

        return response;
    }
    // Изменить товар
    static async UpdateProduct(data, slug) {
        const response = await axios.put(`${egoda_url}/shop/product/detail/${slug}/`, data, {
            // headers: {
            //     'Content-Type': 'multipart/form-data'
            // },
            withCredentials: true
        })

        return response;
    }
    // ! Получить товар
    static async GetAdminProduct(slug) {
        const response = await axios.get(`${egoda_url}/shop/product/detail_admin/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить один товар
    static async GetProduct(slug) {
        const response = await axios.get(`${egoda_url}/shop/product/detail/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
// Категории
    // Добавить новую категорию
    static async AddNewStoreCategory(catData) {
        const response = await axios.post(`${egoda_url}/shop/category/add/`, catData, {
            withCredentials: true
        });
    
        return response;
    }
    // Изменить категорию
    static async UpdateStoreCategory(data, id) {
        const response = await axios.put(`${egoda_url}/shop/category/edit/${id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })

        return response;
    }
    // Получить все категории (сайдбар)
    static async GetStoreCatsSidebar() {
        const response = await axios.get(`${egoda_url}/shop/category/sidebar/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить все категории (без пагинации)
    static async GetStoreCatsAll() {
        const response = await axios.get(`${egoda_url}/shop/category/listall/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить все категории (пагинация)
    static async GetStoreCatsPage(page) {
        const response = await axios.get(`${egoda_url}/shop/category/list/${page}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить одну категорию
    static async GetStoreCategory(slug) {
        const response = await axios.get(`${egoda_url}/shop/category/detail/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Удалить категорию
    static async DeleteStoreCategory(id) {
        const response = await axios.delete(`${egoda_url}/shop/category/delete/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
// Купоны
    // Получить все купоны (пагинация)
    static async GetStoreCouponsPage(page) {
        const response = await axios.get(`${egoda_url}/cart/coupon/list/${page}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить один купон
    static async GetStoreCoupon(id) {
        const response = await axios.get(`${egoda_url}/cart/coupon/detail/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Удалить купон
    static async DeleteStoreCoupon(id) {
        const response = await axios.delete(`${egoda_url}/cart/coupon/detail/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Добавить новый купон
    static async AddNewStoreCoupon(data) {
        const response = await axios.post(`${egoda_url}/cart/coupon/add/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Изменить купон
    static async UpdateCoupon(data, id) {
        const response = await axios.put(`${egoda_url}/cart/coupon/detail/${id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })

        return response;
    }
// Бренды
    // Получить все бренды (сайдбар)
    static async GetStoreBrandSidebar(data) {
        const response = await axios.post(`${egoda_url}/shop/brand/sidebar/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Получить все бренды (пагинация)
    static async GetStoreBrandsPage(page, data) {
        const response = await axios.post(`${egoda_url}/shop/brand/list/${page}/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Получить все бренды (без пагинации)
    static async GetStoreBrandsAll(data) {
        const response = await axios.post(`${egoda_url}/shop/brand/listall/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Получить один бренд
    static async GetStoreBrand(slug) {
        const response = await axios.get(`${egoda_url}/shop/brands/detail/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Удалить бренд
    static async DeleteStoreBrand(slug) {
        const response = await axios.delete(`${egoda_url}/shop/brands/detail/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Добавить новый бренд
    static async AddNewStoreBrands(data) {
        const response = await axios.post(`${egoda_url}/shop/brands/add/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Изменить бренд
    static async UpdateBrands(data, slug) {
        const response = await axios.put(`${egoda_url}/shop/brands/detail/${slug}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })

        return response;
    }
// Категории брендов 
    // Добавить новую категорию
    static async AddNewBrandCategory(catData) {
        const response = await axios.post(`${egoda_url}/shop/category_brand/add/`, catData, {
            withCredentials: true
        });
    
        return response;
    }
    // Изменить категорию
    static async UpdateBrandCategory(data, id) {
        const response = await axios.put(`${egoda_url}/shop/category_brand/edit/${id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        })

        return response;
    }
    // Получить все категории (сайдбар)
    static async GetBrandCatsSidebar() {
        const response = await axios.get(`${egoda_url}/shop/category_brand/sidebar/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить все категории (без пагинации)
    static async GetBrandCatsAll() {
        const response = await axios.get(`${egoda_url}/shop/category_brand/listall/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить все категории (пагинация)
    static async GetBrandCatsPage(page) {
        const response = await axios.get(`${egoda_url}/shop/category_brand/list/${page}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Получить одну категорию
    static async GetBrandCategory(slug) {
        const response = await axios.get(`${egoda_url}/shop/category_brand/detail/${slug}/`, {
            withCredentials: true
        });
    
        return response;
    }
    // Удалить категорию
    static async DeleteBrandCategory(id) {
        const response = await axios.delete(`${egoda_url}/shop/category_brand/delete/${id}/`, {
            withCredentials: true
        });
    
        return response;
    }
// Импорт
    // Импорт с помощью файла Excel
    static async Import(data) {
        const response = await axios.post(`${egoda_url}/shop/product/import/`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        return response;
    }
    // Получить импорт поставщика
    static async GetImport(data) {
        const response = await axios.post(`${egoda_url}/product_import_manager/supplier_import/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Изменить импорт поставщика
    static async UpdateImport(data) {
        const response = await axios.put(`${egoda_url}/product_import_manager/supplier_import/`, data, {
            withCredentials: true
        })

        return response;
    }
// Настройки 
    // ! Получить настройки
    static async GetStoreSettings() {
        const response = await axios.get(`${egoda_url}/shop/settings/get/`, {
            withCredentials: true
        });
    
        return response;
    }
    // ! Сохранить настройки раздела
    static async SaveStoreSettings(data) {
        const response = await axios.post(`${egoda_url}/shop/settings/post/`, data, {
            withCredentials: true
        });
    
        return response;
    }
}