import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";
import { StorePageRoot } from "../utils/consts";
import { useNavigate } from "react-router";

const Page404 = () => {

    const navigate = useNavigate()
    const {windowWidth, setCurPageSlug} = useContext(Context)

    // Назначение слага текущей страницы
    useEffect(() => {
        setCurPageSlug('page404')
    },[])

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Такой страницы не существует. Возможно, она была перенесена или удалена. </div>
                            <div className="row-30-a">
                                <button className="base-btn white h-14-400-i" onClick={() => navigate('/')}>Перейти на главную</button>
                                <button className="base-btn h-14-400-i white" onClick={() => navigate(`${StorePageRoot}`)}>Перейти в магазин</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default Page404;