import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router";
import img_pack from "../img/img";
import { AdminPagesRedRootBase, AdminPagesRoot, AdminUsersRoot } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetPages from "../API/GetPages";

const AdminPagesRedPage = () => {

    const [pagesData, setPagesData] = useState({
        slug: '',
        meta_title: '',
        meta_desc: '',

    })

    const [title, setTitle] = useState('')
    const {slugParam} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const isEdit = location.pathname.includes(AdminPagesRedRootBase)

    // Базовые запросы \ первичный рендер

    const [GetPageDetail] = useFetching( async (slugParam) => {
        const res = await GetPages.GetPageDetail(slugParam)
        // console.log('GetPageDetail complete:' , res.data)
        setTitle(res?.data?.detail?.title)
        setPagesData(prevState => ({
            ...prevState,
            slug: res.data.detail?.slug || prevState.slug,
            meta_title: res.data.detail?.meta_title || prevState.meta_title,
            meta_desc: res.data.detail?.meta_desc || prevState.meta_desc,
          }));
    })

    useEffect(() => {
        if(slugParam && isEdit) {
            GetPageDetail(slugParam)
        }
    },[slugParam])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    const [UpdatePage] = useFetching( async (data) => {
        const res = await GetPages.UpdatePage(data)
        // console.log('UpdatePage complete:' , res.data)
        navigate(`${AdminPagesRoot}`)
        return res;
    })
    
    function submit(e) {
        e.preventDefault()

        if(isEdit) {
            UpdatePage(pagesData)
        }
    }

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
                <div className="col-5">
                    <input name="title" defaultValue={title} className="base-input h-16-400-i gr7" placeholder="Название" type="text" disabled/>
                    <input name="slug" defaultValue={pagesData?.slug} className="base-input h-12-400-i gr7" placeholder="slug" type="text" disabled/>
                </div>
                <div className="col-10">
                    <div className="h-16-500-i">SEO</div>
                    <div className="h-12-500-i">Можно задать SEO настройки для данной страницы</div>
                </div>
                <div className="base_table base_content_1">
                    <table>
                        <tbody>
                            <tr>
                                <td className="h-16-400-i">Meta заголовок</td>
                                <td><input name="meta_title" value={pagesData?.meta_title || ''} onChange={(e) => setPagesData((prevState) => ({...prevState, meta_title: e.target.value}))} type="text" className="base-input h-14-400-i" /></td>
                            </tr>
                            <tr>
                                <td className="h-16-400-i">Meta описание</td>
                                <td><input name="meta_desc" value={pagesData?.meta_desc || ''} onChange={(e) => setPagesData((prevState) => ({...prevState, meta_desc: e.target.value}))} type="text" className="base-input h-14-400-i" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
            </div>
        </form>
        
    )
}

export default AdminPagesRedPage;