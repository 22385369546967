import React, { useContext, useEffect, useState } from "react"
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";
import img_pack from "../img/img";
import globalFunction, { StorePageRoot, egoda_url } from "../utils/consts";
import { useNavigate, useParams } from "react-router";
import { useFetching } from "../hooks/UseFetching";
import GetOrder from "../API/GetOrder";
import { Link } from "react-router-dom";

const OrderPage = () => {

    const [order, setOrder] = useState('')
    const {windowWidth, ShowCart, setCurPageSlug} = useContext(Context)
    const {idParam} = useParams()
    const navigate = useNavigate()

    // Назначение слага текущей страницы
    useEffect(() => {
        setCurPageSlug('order')
    },[])

    // Получение заказа
    const [GetOrderDetailToken] = useFetching( async (token) => {
        const res = await GetOrder.GetOrderDetailToken(token)
        // console.log('GetOrderDetail complete:' , res.data)
        setOrder(res.data.detail)
    })
    
    useEffect(() => {
        if(idParam) {
            GetOrderDetailToken(idParam)
        }
    },[])

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="success-block">
                                <span></span>
                                <div>
                                    <div className="h-20-500-c">Ваш заказ успешно оформлен!</div>
                                    <div className="h-14-400-i gr7">Подробная информация о заказе была отправлена вам на почту {order?.email} </div>
                                </div>
                                </div>
                            <div className="h-16-700-i gr7">Детали заказа:</div>
                            <div className="col">
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}>Заказ №</div>
                                    <div className="h-14-400-i">{order.id}</div>
                                </div>
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}>Статус</div>
                                    <div className="h-14-400-i">{order?.status?.status_name}</div>
                                </div>
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}>Доставка</div>
                                    <div className="h-14-400-i">{order?.delivery === 'PICKUP' ? 'Самовывоз' : order?.delivery === 'DC' ? 'Доставка по Челябинску' : order?.delivery === 'DR' ? 'Доставка по России' : ''}</div>
                                </div>
                            </div>
                            <div className="h-16-700-i gr7">Содержимое заказа</div>
                            <div className="col">
                                <div style={{padding: '20px 10px', display: 'flex', gap: '20px', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i">Товар</div>
                                    <div className="h-14-400-i">Сумма</div>
                                </div>
                                {order?.items?.map((el) => (
                                    <div key={el.product.id} style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #D0D5DD', gap: '20px'}}>
                                        {el.product.image_url ?
                                            <img style={{height: '50px', minWidth: '50px', borderRadius: '10px'}} src={`${egoda_url}${el.product.image_url}`} alt="img" />
                                            : <img style={{height: '50px', minWidth: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                        }
                                        <div className="h-14-400-i gr8" style={{width: '100%'}}>{el.product.name} <span className="h-14-700-i">({el.quantity} шт)</span></div>
                                        <div className="h-14-400-i txtr" style={{minWidth: 'max-content'}}>{globalFunction.withSpaces(globalFunction.split(el.price)[0])} ₽</div>
                                    </div>
                                ))}
                                {order?.is_coupon &&
                                <div className="col" style={{paddingTop: '10px'}}>
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-400-i" style={{alignSelf: 'center'}}>Сумма без скидки</div>
                                        <div className="h-14-400-i">{globalFunction.withSpaces(globalFunction.split(+order?.total_price)[0])} ₽</div>
                                    </div>
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-400-i" style={{alignSelf: 'center'}}>Купон</div>
                                        <div className="h-14-400-i">{order?.code_coupon}</div>
                                    </div>
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-400-i" style={{alignSelf: 'center'}}>Тип скидки</div>
                                        <div className="h-14-400-i">{order.type_discount === 'PERCENT' ? 'Процент скидки' : 'Сумма скидки'}</div>
                                    </div>
                                    <div style={{display: 'flex', gap: '20px', justifyContent: 'space-between'}}>
                                        <div className="h-14-400-i" style={{alignSelf: 'center'}}>Величина скидки</div>
                                        <div className="h-14-400-i">{order?.type_discount === 'PERCENT' ? `${order?.coupon_value} %` : `${order?.coupon_value} ₽`}</div>
                                    </div>
                                </div>
                                }
                                <div style={{padding: '20px 0', gap: '20px', display: 'flex', justifyContent: 'space-between'}}>
                                    <div className="h-14-700-i" style={{alignSelf: 'center'}}>ИТОГО:</div>
                                    <div className="h-30-400-i txtr">{order?.total_price_with_discount && globalFunction.withSpaces(globalFunction.split(order?.total_price_with_discount)[0])} ₽</div>
                                </div>
                            </div>
                            <div className="h-16-700-i gr7">Информация о клиенте</div>
                            <div className="col">
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}>Имя</div>
                                    <div className="h-14-400-i txtr">{order.first_name}</div>
                                </div>
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}>Телефон</div>
                                    <div className="h-14-400-i txtr">{order.tel}</div>
                                </div>
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}>Email</div>
                                    <div className="h-14-400-i txtr">{order.email}</div>
                                </div>
                                <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                    <div className="h-14-400-i" style={{alignSelf: 'center'}}>Адрес доставки</div>
                                    <div className="h-14-400-i txtr">{order.address}</div>
                                </div>
                            </div>
                            <div className="row-20-js">
                                <button className="base-btn white h-14-400-i" onClick={() => navigate('/')}>Перейти на главную</button>
                                <button className="base-btn h-14-400-i white" onClick={() => navigate(`${StorePageRoot}`)}>Перейти в магазин</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default OrderPage;