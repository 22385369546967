import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const ExchangeAndRefundPage = () => {

    const {windowWidth, setCurPageSlug} = useContext(Context)

    // Назначение слага текущей страницы
    useEffect(() => {
        setCurPageSlug('exchangeandrefund')
    },[])

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Условия возврата товаров надлежащего качества</div>
                            <div className="h-14-400-i c-2">Возврат товара надлежащего качества возможен в течение 14 дней, не считая дня получения. Для возврата необходимо сохранить товарный вид, потребительские свойства, а также документ, подтверждающий покупку. Возврат средств Покупателю производится в течение 10 дней с момента получения товара и заявления на возврат.</div>
                            <div className="h-14-400-i c-2">Местом получения возвращаемого товара является пункт выдачи: г. Челябинск, ул. Академика Макеева, 36. Возврат осуществляется тем же способом оплаты, которым был оплачен заказ. Положения о возврате товаров надлежащего качества регулируются статьями 18-24 Закона «О защите прав потребителей».</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Условия возврата товаров ненадлежащего качества</div>
                            <div className="h-14-400-i c-2">Гарантийные случаи рассматриваются в соответствии с Законом «О защите прав потребителей». Признание товарного брака после эксплуатации шин и дисков осуществляется на основании независимой экспертизы, в сроки, установленные ст. 18, 20-22 Закона РФ «О защите прав потребителей» (от 07.02.1992 N 2300-1), за исключением случаев очевидного визуального брака.</div>
                            <div className="h-14-400-i c-2">Если дефект признан заводским браком, возврат средств Покупателю производится в течение 10 дней с момента получения решения по экспертизе и заявления на возврат. Возврат осуществляется тем же способом оплаты, которым был оплачен заказ. В случае обмена товара с заводским дефектом все расходы на доставку и транспортировку покрываются компанией.</div>
                            <div className="h-14-400-i c-2">Политика возврата распространяется на все товары, представленные в каталоге. Для инициации возврата/обмена товара свяжитесь с нами по телефону +7 (351) 776-49-91 или отправьте письмо на tkass74@internet.ru.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Внимание, это важно!</div>
                            <div className="h-14-400-i c-2">При получении шин или дисков обязательно проверьте товар на наличие видимых дефектов. В случае обнаружения брака, товар будет обменян в кратчайшие сроки за счет магазина.</div>
                            <div className="h-14-400-i c-2">Инструкция по примерке дисков перед монтажом:</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'decimal', marginLeft: '20px'}}>
                                <li>Осмотрите новые диски и убедитесь в отсутствии дефектов.</li>
                                <li>Демонтируйте старое колесо с автомобиля.</li>
                                <li>Очистите ступицу от ржавчины и загрязнений.</li>
                                <li>Приложите новый диск к ступице, убедившись, что привалочная плоскость диска полностью прилегает к ступице, тормозной суппорт и другие детали подвески не касаются обода и спиц, крепежные отверстия совпадают с отверстиями ступицы.</li>
                                <li>При использовании пластиковых посадочных колец, сначала проверьте их размер, приложив к ступице и диску.</li>
                                <li>Очистите ступицу от ржавчины и загрязнений.</li>
                            </ul>
                            <div className="h-14-400-i c-2">Шины и диски со следами шиномонтажных работ возврату не подлежат.</div>
                        </div>
                        

                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default ExchangeAndRefundPage;