import React, { useContext, useEffect, useState } from "react"
import globalFunction, { OrderPageRoot, OrderPageRootBase, PostPageRoot, PostPageRootBase, egoda_url, posts } from "../utils/consts";
import { useNavigate } from "react-router";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetCart from "../API/GetCart";
import GetOrder from "../API/GetOrder";
import { Context } from "../App";

const CartOrderPage = () => {

    const {windowWidth, cart, productsAll, setProductsAll, ShowCart, setCurPageSlug} = useContext(Context)

    const [subscribeNews, setSubscribeNews] = useState(false)
    const [condition, setCondition] = useState(true)
    const [conditionError, setConditionError] = useState(false)

    const [radioBtn, setRadioBtn] = useState('rad-1')
    const navigate = useNavigate()

    // Назначение слага текущей страницы
    useEffect(() => {
        setCurPageSlug('cartorder')
    },[])

    // Создать заказ
    const [createOrder] = useFetching( async (data) => {
        const res = await GetOrder.CreateOrder(data)
        // console.log('CreateOrder complete:' , res.data)
        navigate(`${OrderPageRootBase}${res.data?.detail?.unique_token}`)
        ShowCart()
        return res;
    })

    function submit(e) {
        e.preventDefault()
        // console.log(e.target.elements.name.value)
        if(condition) {
            const data = new FormData();
            data.append("first_name", e.target.elements.name.value || '');
            data.append("last_name", e.target.elements.surname.value || '');
            data.append("patronymic", e.target.elements.patronymic.value || '');
            data.append("tel", e.target.elements.tel.value || '');
            data.append("email", e.target.elements.email.value || '');
            data.append("address", e.target.elements.delivery.value || '');
            data.append("postal_code", e.target.elements.postalCode.value || '');
            data.append("city", e.target.elements.city.value || '');
            data.append("subscribe_newsletter", subscribeNews);
            data.append("delivery", radioBtn === 'rad-1' ? 'PICKUP' : radioBtn === 'rad-2' ? 'DC' : 'DR');
            createOrder(data)
        } else {
            setConditionError(true)
        }
    }

    return (
       <>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="cart-row cartorderpage">
                        <div>
                            <form id="cart-order-form" onSubmit={submit} style={{display: 'flex', flexDirection: 'column', gap: '30px', padding: '20px 16px'}}>
                                <div className="h-16-700-i gr7">Детали заказа:</div>
                                <div className="row-30">
                                    <div className="col-5">
                                        <span className="h-14-500-i gr8">Фамилия *</span>
                                        <input name="surname" className="h-16-400-i gr5 base-input" placeholder="Введите фамилию" type="text" required/>
                                    </div>
                                    <div className="col-5">
                                        <span className="h-14-500-i gr8">Имя * </span>
                                        <input name="name" className="h-16-400-i gr5 base-input" placeholder="Введите ваше имя" type="text" required/>
                                    </div>
                                    <div className="col-5">
                                        <span className="h-14-500-i gr8">Отчество</span>
                                        <input name="patronymic" className="h-16-400-i gr5 base-input" placeholder="Введите отчество" type="text"/>
                                    </div>
                                </div>
                                <div className="row-30">
                                    <div className="col-5">
                                        <span className="h-14-500-i gr8">Телефон *</span>
                                        <div className="input_wi wi_l">
                                            <img src={img_pack.input_phone_icon} alt="icon" />
                                            <input name="tel" className="h-16-400-i gr5" placeholder="Введите телефон" type="tel" required/>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <span className="h-14-500-i gr8">Email *</span>
                                        <div className="input_wi wi_l">
                                            <img src={img_pack.input_mail_icon} alt="icon" />
                                            <input name="email" className="h-16-400-i gr5" placeholder="Ваш email" type="email" required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-16-700-i gr7">Детали доставки</div>
                                <div className="col-5">
                                    <div className="row-10">
                                        <span className={`radio_btn ${radioBtn === 'rad-1' ? 'active' : ''}`} onClick={() => setRadioBtn('rad-1')}></span>
                                        <span className="h-14-400-i" style={{cursor: 'pointer'}} onClick={() => setRadioBtn('rad-1')}>Самовывоз</span>
                                    </div>
                                    <div className="row-10">
                                        <span className={`radio_btn ${radioBtn === 'rad-2' ? 'active' : ''}`} onClick={() => setRadioBtn('rad-2')}></span>
                                        <span className="h-14-400-i" style={{cursor: 'pointer'}} onClick={() => setRadioBtn('rad-2')}>Курьерская доставка (г. Челябинск)</span>
                                    </div>
                                    <div className="row-10">
                                        <span className={`radio_btn ${radioBtn === 'rad-3' ? 'active' : ''}`} onClick={() => setRadioBtn('rad-3')}></span>
                                        <span className="h-14-400-i" style={{cursor: 'pointer'}} onClick={() => setRadioBtn('rad-3')}>Доставка ТК (по всей России)</span>
                                    </div>
                                </div>
                                <div className="row-30">
                                    <div className="col-5">
                                        <span className="h-14-500-i gr8">Город</span>
                                        <input name="city" className="h-16-400-i gr5 base-input" placeholder="Введите город" type="text" required/>
                                    </div>
                                    <div className="col-5">
                                        <span className="h-14-500-i gr8">Индекс</span>
                                        <input name="postalCode" className="h-16-400-i gr5 base-input" placeholder="Введите индекс" type="text" required/>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <span className="h-14-500-i gr7">Укажите адрес доставки</span>
                                    <textarea name="delivery" id="delivery" className="base-input h-16-400-i gr5" placeholder="Адрес доставки, дополнительное примечание по доставке / пожелания..." style={{height: '120px'}} />
                                </div>

                            </form>
                            {windowWidth > 1275 &&
                            <div className="col-10">
                                <div className="cart-info-block">
                                    <div className="cart-info-block-img"><img src={`${img_pack.phone_call}`} alt="icon" /></div>
                                    <div className="cart-info-block-content">
                                        <div className="h-18-500-i gr8">Есть вопросы?</div>
                                        <div className="h-14-400-i gr8">Наши специалисты помогут, звоните +7 (351) 776-49-91</div>
                                    </div>
                                </div>
                                <div className="cart-info-block">
                                    <div className="cart-info-block-img"><img src={`${img_pack.shield}`} alt="icon" /></div>
                                    <div className="cart-info-block-content">
                                        <div className="h-18-500-i gr8">Безопасная покупка</div>
                                        <div className="h-14-400-i gr8">Все транзакции защищены SSL сертификатом</div>
                                    </div>
                                </div>
                                <div className="cart-info-block">
                                    <div className="cart-info-block-img"><img src={`${img_pack.eye_off}`} alt="icon" /></div>
                                    <div className="cart-info-block-content">
                                        <div className="h-18-500-i gr8">Защита данных</div>
                                        <div className="h-14-400-i gr8">Ваша конфиденциальность является главным приоритетом</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div>
                            <div className="cart-r-baseBlock">
                                <div className="h-16-700-i gr7">Ваш заказ</div>
                                <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                                <div className="col">
                                    <div style={{padding: '20px 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #D0D5DD'}}>
                                        <div className="h-14-400-i">Товар</div>
                                        <div className="h-14-400-i">Сумма</div>
                                    </div>
                                    {Object.values(productsAll)?.map((el) => (
                                        <div key={el.product_id} style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #D0D5DD', gap: '20px'}}>
                                            {el.image_url ?
                                                <img style={{height: '50px', width: '50px', borderRadius: '10px'}} src={`${egoda_url}${el.image_url}`} alt="img" />
                                                : <img style={{height: '50px', width: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                            }
                                            <div className="h-14-400-i gr8" style={{width: '100%'}}>{el.product_name} <span className="h-14-700-i">({el.quantity} шт)</span></div>
                                            <div className="h-14-400-i" style={{minWidth: 'max-content'}}>{globalFunction.withSpaces(globalFunction.split(el.total_price)[0])} ₽</div>
                                        </div>

                                    ))}
                                </div>

                                <div style={{padding: '20px 0', gap: '20px', display: 'flex', justifyContent: 'space-between'}}>
                                    <div className="h-14-700-i" style={{alignSelf: 'center'}}>ИТОГО:</div>
                                    <div className="h-30-400-i">{cart?.total_price_with_discount && globalFunction.withSpaces(globalFunction.split(cart?.total_price_with_discount)[0])} ₽</div>
                                </div>
                                <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                                <div className="col-10">
                                    <div className="row-10">
                                        <span className={`check_box_mark ${subscribeNews ? 'active' : ''}`} onClick={() => setSubscribeNews(!subscribeNews)}></span>
                                        <span className="h-14-400-i">Я хотел(а) бы подписаться на рассылку компании и получать письма о скидках и информацию по товарах и услугах.</span>
                                    </div>
                                    <div className="row-10">
                                        <span className={`check_box_mark ${conditionError ? 'err' : ''} ${condition ? 'active' : ''}`} onClick={() => setCondition(!condition)}></span>
                                        <span className="h-14-400-i">Я прочитал(а) и принимаю правила и условия сайта *</span>
                                    </div>
                                </div>
                                <button type="submit" form="cart-order-form" className="product_cart_btn h-14-600-i">Подтвердить заказ</button>
                            </div>
                            <div className="cart-r-baseBlock">
                                <div className="h-16-700-i gr7">Оплата и доставка</div>
                                <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                                <div className="h-14-400-i col-10 c-2">
                                    Доставка производится до адреса клиента внутри города Челябинск. При оформлении заказа необходимо указать адресные данные (улица, дом) и выбрать «курьерская доставка».
                                    <ul className="h-14-400-i c-2" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                        <li>Бесплатная доставка при сумме заказа от 10 000 рублей;</li>
                                        <li>Если сумма заказа меньше 10 000 рублей - стоимость доставки 500 рублей;</li>
                                    
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {windowWidth <= 1275 &&
                    <div className="col-10">
                        <div className="cart-info-block">
                            <div className="cart-info-block-img"><img src={`${img_pack.phone_call}`} alt="icon" /></div>
                            <div className="cart-info-block-content">
                                <div className="h-18-500-i gr8">Есть вопросы?</div>
                                <div className="h-14-400-i gr8">Наши специалисты помогут, звоните +7 (351) 776-49-91</div>
                            </div>
                        </div>
                        <div className="cart-info-block">
                            <div className="cart-info-block-img"><img src={`${img_pack.shield}`} alt="icon" /></div>
                            <div className="cart-info-block-content">
                                <div className="h-18-500-i gr8">Безопасная покупка</div>
                                <div className="h-14-400-i gr8">Все транзакции защищены SSL сертификатом</div>
                            </div>
                        </div>
                        <div className="cart-info-block">
                            <div className="cart-info-block-img"><img src={`${img_pack.eye_off}`} alt="icon" /></div>
                            <div className="cart-info-block-content">
                                <div className="h-18-500-i gr8">Защита данных</div>
                                <div className="h-14-400-i gr8">Ваша конфиденциальность является главным приоритетом</div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </section>
       </>
    )
}

export default CartOrderPage;