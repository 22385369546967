import React, { useContext, useEffect, useState } from "react"
import { Splide, SplideTrack, SplideSlide} from '@splidejs/react-splide'
import img_pack from "../img/img";
import { Context } from "../App";
import {products, cats, posts, StorePageRoot, egoda_url} from "../utils/consts";
import ProductExample from "./components/Product_example";
import YandexMap from "./components/YandexMap";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchBlock from "./components/SearchBlock";
import ProductsCatNav from "./components/ProductsCatNav";
import GetMain from "../API/GetMain";
import Map2gis from "./components/Map2gis";
import LazyImage from "./components/LazyImage";

const Main = () => {

    const {windowWidth, setCurPageSlug} = useContext(Context)
    const [productsAll, setProductsAll] = useState([])
    const [brandsAll, setBrandsAll] = useState([])
    const [selectedProductSeason, setSelectedProductSeason] = useState('Все')
    const [selectedBrandsCat, setSelectedBrandsCat] = useState('Все')

    const navigate = useNavigate()
    const location = useLocation()
    
    const [selectedBrand, setSelectedBrand] = useState({name: '', slug: ''});
    
    // Назначение слага текущей страницы
    useEffect(() => {
        setCurPageSlug('main')
    },[])

    useEffect(() => {
        if(selectedBrand?.slug !== '') {
            navigate(`${StorePageRoot}`, { state: { initialBrand: selectedBrand } })
        }
    },[selectedBrand?.slug])
    
    // Получение всех товаров
    const [GetMainProducts, productsIsLoading] = useFetching( async (productData, page) => {
        const res = await GetMain.GetMainProducts(productData, page)
        // console.log('GetMainProducts complete:' , res.data)
        setProductsAll(res.data.result)
    })
    // Получение всех брендов
    const [GetMainBrands, brandsIsLoading] = useFetching( async (productData, page) => {
        const res = await GetMain.GetMainBrands(productData, page)
        // console.log('GetMainBrands complete:' , res.data)
        setBrandsAll(res.data.result)
    })

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("category", 'tires');
        formdata.append("brand", '');
        formdata.append("item_quantity", 10);
        formdata.append("is_popular", 1);
        formdata.append("season", (selectedProductSeason === 'Все' ? '' : selectedProductSeason) || '');
        GetMainProducts(formdata, 1)
    },[selectedProductSeason])

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("category", selectedBrandsCat === 'Шины' ? 'tires' : selectedBrandsCat === 'Диски' ? 'disks' : '');
        formdata.append("item_quantity", 10);
        formdata.append("is_popular", 1);
        GetMainBrands(formdata, 1)
    },[selectedBrandsCat])

    return (
    <>
        <section className="block-base" style={{position: 'relative'}}>
        <div
            className="search-lazy-image-background"
            style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            zIndex: -1,
            }}
        >
            <LazyImage
            src={img_pack.search_block_back_full}
            alt="img"
            style={{
                objectFit: 'cover',
                objectPosition: 'center 35%',
                width: '100%',
                height: '100%',
            }}
            />
        </div>
            <div className="container">
                <div className="search-block-content">
                    <SearchBlock />
                    <div>
                        <span className="h-36-500-i white">Шины и диски для авто в Челябинске</span>
                        <span className="h-16-400-i white">В наличии и под заказ</span>
                        <div style={{display: 'flex'}}><Link to={StorePageRoot} className="base-btn white h-14-400-j">Подробнее</Link></div>
                    </div>
                    <div className="search-block-content-img-containter">
                        <LazyImage src={img_pack.search_block_back} alt="img" />
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="main-info-row">
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Отзывы наших покупателей</span>
                                <div><Link to={'http://avito.ru/brands/i318698345'} target="_blank" className="h-14-400-j">Смотреть отзывы</Link></div>
                            </div>
                            <div><LazyImage src={img_pack.main_infoBlock_avito} alt="img" /></div>
                        </div>
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Нужна помощь в подборе?</span>
                                <div><Link to={'/contact_po'} state={{prevLocation: location}} className="h-14-400-j">Задать вопрос</Link></div>
                            </div>
                            <div><LazyImage src={img_pack.main_infoBlock_tires} alt="img" /></div>
                        </div>
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Отзывы наших покупателей</span>
                                <div><Link to={'https://go.2gis.com/5tv86'} target="_blank" className="h-14-400-j">Смотреть отзывы</Link></div>
                            </div>
                            <div><LazyImage src={img_pack.main_infoBlock_2gis} alt="img" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-26-400-j gr8">Лучшие предложения шин</div>
                        <ProductsCatNav catNav={['Зимние','Летние']} activeItems={selectedProductSeason} setActiveItems={setSelectedProductSeason}/>
                    </div>
                    {(productsIsLoading) && <div className="loader-container"><div className="loader"></div></div>}
                    <div className="products-block">
                        {!productsIsLoading && productsAll?.map((el) => (
                            <ProductExample key={el.id} res={el}></ProductExample>
                        ))}
                    </div>
                    {windowWidth <= 600 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}} onClick={() => navigate(`${StorePageRoot}`)}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-26-400-j gr8">Популярные бренды</div>
                        <ProductsCatNav catNav={['Шины','Диски']} activeItems={selectedBrandsCat} setActiveItems={setSelectedBrandsCat}/>
                    </div>
                    {(brandsIsLoading) && <div className="loader-container"><div className="loader"></div></div>}
                    <div className="brands-block">
                        {!brandsIsLoading && brandsAll?.map((el) => (
                            <div key={el.id} className="brand-ex">
                                {el.image_logo?.file ?
                                    <LazyImage src={`${egoda_url}${el.image_logo?.file}`} alt="img"  onClick={() => setSelectedBrand({name: el.name, slug: el.slug})}/>
                                    : <div className="h-20-400-i" onClick={() => setSelectedBrand({name: el.name, slug: el.slug})}>{el.name}</div>
                                }
                            </div>
                        ))}
                    </div>
                    {windowWidth <= 600 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}} onClick={() => navigate(`${StorePageRoot}`)}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="h-26-400-j c-2">Почему лучше покупать у нас?</div>
                    <div className="prv-block-row">
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <LazyImage src={img_pack.prv_truck} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Доставка по Челябинску</div>
                            <div className="h-16-400-i">Доставим в удобное время и место.</div>
                        </div>
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <LazyImage src={img_pack.prv_map} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Доставка ТК во все регионы РФ</div>
                            <div className="h-16-400-i">Быстрые сроки доставки и удобное получение</div>
                        </div>
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <LazyImage src={img_pack.prv_card} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Различные способы оплаты</div>
                            <div className="h-16-400-i">Оплатите удобным способом</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="contacts-block">
                        <div className="contact-info">
                            <div className="h-26-400-j c-2">Контакты</div>
                            <div className="contacts-row">
                                <div className="contact-ex">
                                    <a href="tel:+73517764991" className="brand h-24-600-i">+7 (351) 776-49-91</a>
                                    <span className="h-16-400-i">пн-пт: 9:00-18:00, сб-вс: выходной</span>
                                    <Link to={'/contact_po'} state={{prevLocation: location}} className="call_btn white h-14-400-j">Заказать звонок</Link>
                                </div>
                                <div className="contact-ex">
                                    <a href="mailto:tkass74@internet.ru" className="brand h-24-600-i">tkass74@internet.ru</a>
                                    <span className="h-16-400-i gr5">Напишите нам</span>
                                </div>
                                <div className="contact-ex">
                                    <div className="row-15-a">
                                        <img src={`${img_pack.contact_mappinIcon}`} alt="ic" />
                                        <span className="brand h-16-400-i">Офис</span>
                                    </div>
                                    <span className="h-16-400-i">Челябинск, ​Академика Макеева, 36, офис 25</span>
                                </div>
                                <div className="row-15">
                                    <Link to={'https://wa.me/79511134816'}><img src={`${img_pack.contact_whatsappIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                    <Link to={'https://t.me/TKACC74'}><img src={`${img_pack.contact_telegramIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Map2gis></Map2gis>
            </div>
        </section>
    </>
    )
}

export default Main;