import React, { createContext, useEffect, useRef, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Layout from "./Pages/Layout"
import Main from "./Pages/Main"
import { adminRoutes, otherRoutes, publicRoutes } from "./utils/routes"
import AdminLayout from "./Pages/AdminLayout"
import AdminGeneralPage from "./Pages/AdminGeneralPage"
import ItemPo from "./Pages/components/Item_po"
import Popup from "./Pages/components/Popup"
import MediafilesPo from "./Pages/components/MediafilesPo"
import { ru } from "date-fns/locale"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { ToastContainer } from 'react-toastify';
import { Page404_Root, ScrollToTop } from "./utils/consts"
import ContactPo from "./Pages/components/Contact_po"
import Plug from "./Pages/Plug"
import GetCart from "./API/GetCart"
import { useFetching } from "./hooks/UseFetching"
import { useCart } from "./hooks/UseCart"
import { UseCatsBrandSidebar } from "./hooks/UseCatsBrandSidebar"
import GetPages from "./API/GetPages"

export const Context = createContext(null)

registerLocale('ru', ru)
setDefaultLocale('ru');

const App = () => {
    
  const location = useLocation()
  const prevLocation = location.state?.prevLocation
  const queryParams = new URLSearchParams(window.location.search)

  const { allBrandsSide, allCatsSide, GetStoreCatsSidebar, GetStoreBrandSidebar } = UseCatsBrandSidebar();
  const { cart, setCart, productsAll, setProductsAll, ShowCart, UpdateCart, updateQuantity, AddCart, DeleteFromCart } = useCart();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [breadCrumbs, setBreadCrumbs] = useState([])
  const [curModal, setCurModal] = useState(false)
  
  // getHelmet (metaTitle & metaDesc)
  const [curPageSlug, setCurPageSlug] = useState('')
  const [helmetData, setHelmetData] = useState({
    slug: '',
    meta_title: 'АССАВТО - Купить шины и диски для авто в Челябинске',
    meta_desc: '',
  })

  const [GetPageDetail] = useFetching( async (slug) => {
    const res = await GetPages.GetPageDetail(slug)
    // console.log('GetPageDetail complete:' , res.data)
    setHelmetData((prevState) => ({
      ...prevState,
      slug: res.data.detail?.slug || prevState.slug,
      meta_title: res.data.detail?.meta_title || prevState.meta_title,
      meta_desc: res.data.detail?.meta_desc || prevState.meta_desc,
    }))
  })
  
  useEffect(() => {
    // Сбрасываем данные helmetData перед загрузкой новой страницы
    setHelmetData({
      slug: '',
      meta_title: 'АССАВТО - Купить шины и диски для авто в Челябинске',
      meta_desc: '',
    })
  },[location.pathname])

  useEffect(() => {
    if(curPageSlug !== null && curPageSlug !== '') {
      // console.log('curPageSlug', curPageSlug)
      GetPageDetail(curPageSlug)
      setCurPageSlug('')
    }
  },[curPageSlug])

  
  useEffect(() => {
    const handleResize = () => {
    setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Убираем слушатель события при размонтировании компонента
    return () => {
    window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    ShowCart()
  },[])

  const setOrDeleteParam = (key, value) => {
    // Если значение существует и не пустое
    if (value && value !== '') {
        if (typeof value === 'object') {
          // Если значение объекта (массив или объект), сериализуем в JSON
          if((value?.slug || value?.value) && (value?.slug !== '' || value?.value !== '')) {
              queryParams.set(key, JSON.stringify(value));
          } else {
              queryParams.delete(key);
          }
        } else {
          // Иначе, добавляем значение как есть (строка, число)
          queryParams.set(key, value);
        }
    } else {
      // Если значение пустое или undefined, удаляем параметр
      queryParams.delete(key);
    }
  };

  return (
    <Context.Provider
      value={{windowWidth,
        ShowCart, UpdateCart,DeleteFromCart, updateQuantity, AddCart,
        cart, setCart,
        productsAll, setProductsAll,
        allBrandsSide, allCatsSide, GetStoreCatsSidebar, GetStoreBrandSidebar,
        breadCrumbs, setBreadCrumbs,
        curModal, setCurModal,
        setOrDeleteParam, queryParams,
        curPageSlug, setCurPageSlug,
        helmetData, setHelmetData,
      }}
    >
      <ScrollToTop />
      <div>
        <Routes location={prevLocation || location}>

          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminGeneralPage />}/>
            {adminRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
          </Route>

          <Route path="/" element={<Layout />}>
            <Route index element={<Main />}/>
            {publicRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
            {otherRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
            <Route path="*" element={ <Navigate to={`${Page404_Root}`} />}/> 
          </Route>

        </Routes>
        {prevLocation && (
        <Routes>
          <Route path="contact_po" element={<Popup><ContactPo /></Popup>} />
          <Route path="item_po" element={<Popup><ItemPo /></Popup>} />
          <Route path="mediafiles_po" element={<Popup><MediafilesPo /></Popup>} />
        </Routes>
        )}
      </div>
      <ToastContainer />
    </Context.Provider>
  )
}

export default App;
