import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const PaymentAndDeliveryPage = () => {

    const {windowWidth, setCurPageSlug} = useContext(Context)

    // Назначение слага текущей страницы
    useEffect(() => {
        setCurPageSlug('paymentanddelivery')
    },[])

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Курьерская доставка по г. Челябинск</div>
                            <div className="h-14-400-i c-2">Доставка производится до адреса клиента внутри города. При оформлении заказа необходимо указать адресные данные (улица, дом) и выбрать «курьерская доставка».</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Бесплатная доставка при сумме заказа от 10 000 рублей;</li>
                                <li>Если сумма заказа меньше 10 000 рублей - стоимость доставки 500 рублей;</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Самовывоз с ПВЗ</div>
                            <ul className="h-14-400-i gr8" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                <li>Самовывоз заказа из Пункта Выдачи Заказов возможен в Пн-Пт с 09:00-18:00;</li>
                                <li>Обязательно дождитесь сообщения, что заказ готов к выдаче;</li>
                                <li>В некоторых случаях может потребоваться предоплата, если стоимость заказа окажется большой, или для удаленных регионов.</li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Доставка транспортными компаниями</div>
                            <div className="h-14-400-i c-2">Осуществляется по всей территории РФ. Передаём товар в транспортную компанию после оплаты. Доставка до терминала ТК в г. Челябинск бесплатно. Клиентом оплачивается стоимость доставки до пункта выдачи ТК при получении.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default PaymentAndDeliveryPage;