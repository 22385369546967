import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Pagination from "./components/Pagination";
import img_pack from "../img/img";
import { AdminPagesRedRootBase, AdminStoreImportRoot, AdminStoreNewRoot, AdminStoreRedRootBase, ProductPageRootBase, egoda_url } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";
import { Context } from "../App";
import GetPages from "../API/GetPages";

const AdminPagesPage = () => {

    const {setOrDeleteParam, queryParams} = useContext(Context)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalProducts, setTotalProducts] = useState(null)
    const [curSlug, setCurSlug] = useState('');
    const [query, setQuery] = useState(queryParams.get('query') || '');

    const [pagesAll, setPagesAll] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    
    // checbox click
    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }
    
    // Получение всех страниц
    const [GetPagesList] = useFetching( async (page) => {
        const formdata = new FormData();
        formdata.append("query", query || ''); //поиск по тексту
        console.log(query)
        const res = await GetPages.GetPagesList(page, query)
        console.log('GetPagesList complete:' , res.data)
        setPagesAll(res.data?.data)
        setTotalPages(res.data?.total_pages)
        setTotalProducts(res.data?.total)
        navigate(`?${queryParams.toString()}`);
    })
    
    const prevQueryRef = useRef(query);
    useEffect(() => {
        setOrDeleteParam('query', query);
        if (prevQueryRef.current !== query) {
            if(currentPage !== 1) {
                setCurrentPage(1)
            } else {
                GetPagesList(1)
            }
            prevQueryRef.current = query;
        }
    }, [query]);

    const prevPageRef = useRef(currentPage);
    useEffect(() => {
        if (prevPageRef.current !== currentPage) {
            setOrDeleteParam('page', currentPage);
            GetPagesList(currentPage);
            prevPageRef.current = currentPage;
        }
    }, [currentPage]);
    useEffect(() => {
        if(queryParams.get('page') && +queryParams.get('page') > 1) {
            setCurrentPage(+queryParams.get('page'))
        } else {
            GetPagesList(1)
        }
    },[])

    function submit(e) {
        e.preventDefault()
        setQuery(e.currentTarget.elements.search.value)
    }

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Страницы</div>
                    <span className="h-12-500-i ba-or">{totalProducts} шт</span>
                </div>
                <div className="row-10-a">
                    <form className={`header-search-form`} onSubmit={submit} style={{display: 'flex', gap: '5px'}}>
                        <input className="base-input" defaultValue={query} name="search" type="text" placeholder="Найти"/>
                        <button type="submit"><img src={img_pack.search_icon} alt="ic" /></button>
                    </form>
                </div>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Название
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {pagesAll?.map((el) => (
                        <tr key={el.id}>
                            <td className="t-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    <Link to={`${AdminPagesRedRootBase}${el.slug}`} style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                                        <span className="h-14-500-i gr7">{el.title}</span>
                                    </Link>
                                </div>
                            </td>
                            <td className="t-icons">
                                <div>
                                    {/* <Link to={`${ProductPageRootBase}${el.category_slug}/${el.slug}`}><img src={img_pack.articles_eye_icon} alt="eye_icon" /></Link> */}
                                    <Link to={`${AdminPagesRedRootBase}${el.slug}`}><img src={img_pack.articles_edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => {setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => {setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="h-14-600-i">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminPagesPage;