import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import CustomSelect from "./components/Custom_select";
import { AdminStoreCatRedRootBase, AdminStoreCatRoot } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetStore from "../API/GetStore";

const AdminStoreCatRedPage = () => {

    // Первичные запросы

    const [tagsBtns, setTagsBtns] = useState([
        {
            id: 0,
            name: 'название',
            tag: '{{название}}',
        },
        {
            id: 1,
            name: 'родительская категория',
            tag: '{{родительская_категория}}',
        },
        {
            id: 2,
            name: 'категория',
            tag: '{{категория}}',
        },
        {
            id: 3,
            name: 'атрибут: Ширина: название',
            tag: '{{атрибут: Ширина: название}}',
        },
        {
            id: 4,
            name: 'атрибут: Ширина: значение',
            tag: '{{атрибут: Ширина: значение}}',
        },
        {
            id: 5,
            name: 'количество',
            tag: '{{количество}}',
        },
        {
            id: 6,
            name: 'цена',
            tag: '{{цена}}',
        },
    ])

    const [slug, setSlug] = useState('')
    const [catsDetail, setCatsDetail] = useState([])
    const [catsAll, setCatsAll] = useState([])
    const [catsAddSelected, setCatsAddSelected] = useState([])
    const [metaTitle, setMetaTitle] = useState('')
    const [metaDesc, setMetaDesc] = useState('')
    const [imgTitle, setImgTitle] = useState('')
    const [imgAlt, setImgAlt] = useState('')
    const [activeInput, setActiveInput] = useState(null);
    
    const [itemPodata, setItemPodata] = useState({title: 'Категория будет удалена', desc: 'Вы уверены, что хотите удалить категорию безвозвратно?'});
    const {slugParam} = useParams()
    const location = useLocation()
    const isEdit = location.pathname.includes(AdminStoreCatRedRootBase)
    const navigate = useNavigate()
    
    const [GetStoreCatsAll] = useFetching( async () => {
        const res = await GetStore.GetStoreCatsAll()
        setCatsAll(res.data.data)
        // console.log('GetStoreCatsAll complete:' , res.data)
    })
    
    const [GetStoreCategory] = useFetching( async (cat) => {
        const res = await GetStore.GetStoreCategory(cat)
        setCatsDetail(res.data)
        setMetaTitle(res.data?.meta_title)
        setMetaDesc(res.data?.meta_desc)
        setImgTitle(res.data?.image_title_template)
        setImgAlt(res.data?.image_alt_template)
        // console.log('GetStoreCategory complete:' , res.data)
        if (res.data) {
            const form = document.querySelector('.new_category_form')
            form.elements.cat_name.value = res.data?.name
            form.elements.cat_slug.value = res.data?.slug
            res.data.parent && setCatsAddSelected([{value: res.data.parent?.slug, name: res.data.parent?.name}])
        }
    })
        
    useEffect(() => {
        if(isEdit && slugParam) {
            GetStoreCategory(slugParam)
        }
        GetStoreCatsAll()
    },[])

    // Добавление\изменение новой категории

    const [AddNewStoreCategory] = useFetching( async (catData) => {
        const res = await GetStore.AddNewStoreCategory(catData)
        // console.log('AddNewStoreCategory complete:' , res.data)
        navigate(`${AdminStoreCatRoot}`)
        return res;
    })
    
    const [UpdateStoreCategory] = useFetching( async (catData) => {
        const res = await GetStore.UpdateStoreCategory(catData, catsDetail?.id)
        // console.log('UpdateStoreCategory complete:' , res.data)
        navigate(`${AdminStoreCatRoot}`)
        return res;
    })

    function submit(e) {
        e.preventDefault()
        const name = e.target.elements.cat_name.value
        const curSlug = slugify(e.target.elements.cat_slug.value, { lower: true })
        const parent = catsAddSelected.length > 0 ? catsAddSelected[0]?.value : ''

        const catData = new FormData();
        catData.append("name", name);
        catData.append("slug", curSlug);
        catData.append("parent", parent);
        catData.append("meta_title", metaTitle || '');
        catData.append("meta_desc", metaDesc || '');
        catData.append("image_title_template", imgTitle || '');
        catData.append("image_alt_template", imgAlt || '');

        if(isEdit) {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                UpdateStoreCategory(catData)
            }
        } else {
            if (name !== null && name !== '' && curSlug !== null && curSlug !== '') {
                AddNewStoreCategory(catData)
            }
        }
        
    }

    function slugifyTitle(e) {
        const newTitle = e.target.value;
        const newSlug = slugify(newTitle, { lower: true });
        setSlug(newSlug)
    }

    // // Удаление категории
    const [DeleteStoreCategory] = useFetching( async (id) => {
        const res = await GetStore.DeleteStoreCategory(id)
        // console.log('DeleteStoreCategory complete:' , res.data)
        navigate(`${AdminStoreCatRoot}`)
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteStoreCategory(catsDetail?.id)
        }
    }, [location.state])

    // Добавление тега в активный инпут
    const handleAddTag = (tag) => {
        if (activeInput === 'metaTitle') {
            setMetaTitle((prev) => (prev ? prev + ' ' + tag : tag));
        } else if (activeInput === 'metaDesc') {
            setMetaDesc((prev) => (prev ? prev + ' ' + tag : tag));
        } else if (activeInput === 'imgTitle') {
            setImgTitle((prev) => (prev ? prev + ' ' + tag : tag));
        } else if (activeInput === 'imgAlt') {
            setImgAlt((prev) => (prev ? prev + ' ' + tag : tag));
        }
    };

    return (
        <div className="row-10">
            <div className="admin_content_base" style={{flex: '1', alignSelf: 'flex-start'}}>
                <form onSubmit={submit} className="new_category_form">
                    <span className="h-18-500-i gr7">{isEdit ? 'Изменить категорию' : 'Добавить новую категорию'}</span>
                    <div className="col-5">
                        <span className="h-12-600-i gr7">Название новой категории</span>
                        <input onChange={slugifyTitle} name="cat_name" className="base-input h-12-400-i gr7" placeholder="Название" type="text" required/>
                    </div>
                    <div className="col-5">
                        <span className="h-12-600-i gr7">Slug категории</span>
                        <input name="cat_slug" defaultValue={slug} className="base-input h-12-400-i gr7" placeholder="Slug" type="text" required/>
                    </div>
                    <div className="col-5">
                        <span className="h-12-600-i gr7">Родительская категория</span>
                        <CustomSelect allOption={catsAll} ActiveItems={catsAddSelected} setActiveItems={setCatsAddSelected} single={true} enclosure={false} placeholder={'Выбрать категорию'}/>
                    </div>
                    <div className="row-20-js">
                        <button className="admin_save_btn" type="submit">
                            <img src={img_pack.save_icon} alt="save_icon" />
                            <span className="h-12-600-i white">{isEdit ? 'Изменить' : 'Добавить'}</span>
                        </button>
                        {isEdit &&
                        <Link to={'/item_po'} state={{prevLocation: location, itemPodata: itemPodata}} className="admin_delete_btn">
                        <img src={img_pack.trash_icon} alt="trash_icon" />
                        <span className="h-12-600-i white">Удалить</span>
                    </Link>
                        }
                    </div>
                </form>
            </div>
            <div className="col-10" style={{flex: '1'}}>
                <div className="col-10" style={{padding: '10px'}}>
                    <div className="h-16-500-i">SEO</div>
                    <div className="h-12-500-i">SEO настройки можно задавать в общем виде. Можно задавать на уровне выше: в категориях и родительских категориях. В случае, если в товаре не заданы настройки, то будут использованы настройки из категории, если не заданы там, то из родительской категории.</div>
                </div>
                <div className="base_content_1 col-20" style={{padding: '24px 20px'}}>
                    <div className="col-5">
                        <div className="h-12-600-i gr7">Meta заголовок товаров</div>
                        <input onFocus={() => setActiveInput('metaTitle')}
                            name="meta_title" 
                            value={metaTitle || ''} 
                            onChange={(e) => setMetaTitle(e.target.value)} 
                            type="text" 
                            className="base-input h-14-400-i" 
                            placeholder="{{родительская категория}} {{название}} купить " 
                        />
                    </div>
                    <div className="col-5">
                        <div className="h-12-600-i gr7">Meta описание товаров</div>
                        <input onFocus={() => setActiveInput('metaDesc')} 
                            name="meta_desc" 
                            value={metaDesc || ''} 
                            onChange={(e) => setMetaDesc(e.target.value)} 
                            type="text" 
                            className="base-input h-14-400-i" 
                            placeholder="{{родительская категория}} {{название}} купить " />
                    </div>
                    <div className='tags-row'>
                        {tagsBtns?.map((el) => (
                            <button key={el.id} className='ba h-12-500-i gr7 tag-button' type="button" onClick={() => handleAddTag(`${el.tag}`)} >{el.name}</button>
                        ))
                        }
                    </div>
                </div>
                <div style={{padding: '10px'}} className="h-12-500-i">Шаблонные настройки для alt и title изображений товара</div>
                <div className="base_content_1 col-20" style={{padding: '24px 20px'}}>
                    <div className="col-5">
                        <div className="h-12-600-i gr7">title изображения</div>
                        <input 
                            onFocus={() => setActiveInput('imgTitle')} 
                            name="imgTitle" 
                            value={imgTitle || ''} 
                            onChange={(e) => setImgTitle(e.target.value)} 
                            type="text" 
                            className="base-input h-14-400-i" 
                            placeholder="Введите..." 
                        />
                    </div>
                    <div className="col-5">
                        <div className="h-12-600-i gr7">alt изображения</div>
                        <input 
                            onFocus={() => setActiveInput('imgAlt')} 
                            name="imgAlt" 
                            value={imgAlt || ''} 
                            onChange={(e) => setImgAlt(e.target.value)} 
                            type="text" 
                            className="base-input h-14-400-i" 
                            placeholder="Введите..." 
                        />
                    </div>
                    <div className='tags-row'>
                        {tagsBtns?.map((el) => (
                            <button key={el.id} className='ba h-12-500-i gr7 tag-button' type="button" onClick={() => handleAddTag(`${el.tag}`)} >{el.name}</button>
                        ))
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdminStoreCatRedPage;