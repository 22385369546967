import React, { useContext, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Context } from "../App";
import { Helmet } from 'react-helmet';

const Layout = () => {

    const {helmetData, setHelmetData} = useContext(Context)
    const location = useLocation();

    return (
        <>
            <Helmet>
                <title>{helmetData?.meta_title}</title>
                <meta name="description" content={helmetData?.meta_desc} />
            </Helmet>
            <Header />
                <main>
                    <Outlet />
                </main>
            <Footer />
        </>
        
    )
}

export default Layout;