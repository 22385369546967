import React, { useEffect, useRef, useState } from "react"
import img_pack from "../../img/img"
import ToggleBtn from "./toggleBtn"
import { TagsInput } from 'react-tag-input-component';

const StoreSettingsTabs = ({settingsTabs, setSettingsTabs}) => {

    const [tagsBtns, setTagsBtns] = useState([
        {
            id: 0,
            name: 'поиск',
            tag: '{{поиск}}',
        },
        {
            id: 1,
            name: 'цена_от',
            tag: '{{цена_от}}',
        },
        {
            id: 2,
            name: 'цена_до',
            tag: '{{цена_до}}',
        },
        {
            id: 3,
            name: 'категория',
            tag: '{{категория}}',
        },
        {
            id: 4,
            name: 'сортировка',
            tag: '{{сортировка}}',
        },
        {
            id: 5,
            name: 'бренд',
            tag: '{{бренд}}',
        },
        {
            id: 6,
            name: 'популярные',
            tag: '{{популярные}}',
        },
        {
            id: 7,
            name: 'ширина_шины',
            tag: '{{ширина_шины}}',
        },
        {
            id: 8,
            name: 'профиль_шины',
            tag: '{{профиль_шины}}',
        },
        {
            id: 9,
            name: 'диаметр_шины',
            tag: '{{диаметр_шины}}',
        },
        {
            id: 10,
            name: 'сезон_шины',
            tag: '{{сезон_шины}}',
        },
        {
            id: 11,
            name: 'шипы_шины',
            tag: '{{шипы_шины}}',
        },
        {
            id: 12,
            name: 'ширина_диска',
            tag: '{{ширина_диска}}',
        },
        {
            id: 13,
            name: 'диаметр_диска',
            tag: '{{диаметр_диска}}',
        },
        {
            id: 14,
            name: 'et_диска',
            tag: '{{et_диска}}',
        },
        {
            id: 15,
            name: 'pcd_диска',
            tag: '{{pcd_диска}}',
        },
        {
            id: 16,
            name: 'ступица_диска',
            tag: '{{ступица_диска}}',
        },
        {
            id: 17,
            name: 'тип_диска',
            tag: '{{тип_диска}}',
        },
    ])

    const [activeInput, setActiveInput] = useState(null);
    const [selectedBtn, setSelectedBtn] = useState('1')

    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    // Добавление тега в активный инпут
    const handleAddTag = (tag) => {
        setSettingsTabs((prevState) => {
            if (activeInput === 'metaTitle') {
                const updatedTitle = prevState.meta_title_template
                    ? prevState.meta_title_template + ' ' + tag
                    : tag;
                return { ...prevState, meta_title_template: updatedTitle };
            } else if (activeInput === 'metaDesc') {
                const updatedDesc = prevState.meta_desc_template
                    ? prevState.meta_desc_template + ' ' + tag
                    : tag;
                return { ...prevState, meta_desc_template: updatedDesc };
            }
            return prevState;
        });
    };

    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1" style={{width: 'max-content'}}>
                <button style={{minWidth: '200px'}} type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick}>
                    <img_pack.settings_icon />
                    <div className="h-14-500-i">Основные</div>
                </button>
            </div>
            {selectedBtn === '1' &&
            <>
            <div className="col-10" style={{padding: '10px'}}>
                <div className="h-16-500-i">SEO</div>
                <div className="h-12-500-i">SEO настройки можно задавать в общем виде и в шаблонном варианте. Данные настройки будут применяться на странице поиска товаров при существующих фильтрах. Если магазин открыт без фильтров, то будут показываться meta из настроек страницы “Магазин”. </div>
            </div>
            <div className="productRed_content base_content_1">
            <div className="base_table base_content_1">
                    <table>
                        <tbody>
                            <tr>
                                <td className="h-16-400-i">Meta заголовок</td>
                                <td><input onFocus={() => setActiveInput('metaTitle')}
                                    name="meta_title" 
                                    value={settingsTabs?.meta_title_template || ''} 
                                    onChange={(e) => setSettingsTabs((prevState) => ({...prevState, meta_title_template: e.target.value}))} 
                                    type="text" 
                                    className="base-input h-14-400-i" 
                                    placeholder="{{родительская категория}} {{название}} купить " />
                                </td>
                            </tr>
                            <tr>
                                <td className="h-16-400-i">Meta описание</td>
                                <td><input onFocus={() => setActiveInput('metaDesc')} 
                                    name="meta_desc" 
                                    value={settingsTabs?.meta_desc_template || ''} 
                                    onChange={(e) => setSettingsTabs((prevState) => ({...prevState, meta_desc_template: e.target.value}))}
                                    type="text" 
                                    className="base-input h-14-400-i" 
                                    placeholder="{{родительская категория}} {{название}} купить " />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='tags-row' style={{padding: '20px'}}>
                        {tagsBtns?.map((el) => (
                            <button key={el.id} className='ba h-12-500-i gr7 tag-button' type="button" onClick={() => handleAddTag(`${el.tag}`)} >{el.name}</button>
                        ))
                        }
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    )
}

export default StoreSettingsTabs;